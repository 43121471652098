<template>
  <div>
    <Alert v-if="alert && alert.layer === 'admin'" :alert="alert" />
    <div class="container">
      <div class="mx-auto loginContent">
        <div class="card text-center my-5">
          <div class="card-body m-3">
            <div class="py-5">
              <img
                src="@/assets/images/logos/OneOutdoorLogo_2020_color.png"
                height="40"
                alt="OneOutdoor Logo"
                v-if="tenant.stateLogoUrl === 'jira'"
              />
              <img
                :src="tenant.stateLogoUrl"
                height="40"
                :alt="tenant.name + ' Logo'"
                v-if="tenant.stateLogoUrl !== 'jira'"
              />
            </div>
            <div class="row">
              <div class="col col-lg-6 col-md-6 col-sm-12 col-12 right-border">
                <div class="pb-2">
                  <img
                    :src="
                      require(`@/assets/images/logos/${tenant.departmentLogoUrl}`)
                    "
                    height="60"
                    :alt="tenant.name + ' Department Logo'"
                    v-if="tenant.departmentLogoUrl !== 'jira'"
                  />
                  <h4 class="pt-3 text-left pb-1">
                    Sign in with your corporate ID
                  </h4>
                  <button type="button" class="btn btn-primary">
                    IllinoisTest
                  </button>
                </div>
                <div class="py-3 top-border">
                  <img
                    src="@/assets/images/logos/nic_logo.png"
                    height="60"
                    alt="NIC Logo"
                  />
                  <h4 class="pt-3 text-left pb-1">
                    Sign in with your corporate ID
                  </h4>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="azureAdAuth"
                  >
                    AzureNIC
                  </button>
                </div>
              </div>
              <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <h4 class="text-left pb-1">
                  Sign in with your username and password
                </h4>
                <FormErrorAlert
                  v-if="errors.length > 0"
                  :errors="errors"
                  :formRef="this.$refs.adminLoginForm"
                />
                <ValidationObserver ref="adminLoginForm">
                  <form @submit.prevent="onSubmit">
                    <div class="form-group text-left">
                      <TextInput
                        rules="required"
                        name="Username"
                        id="loginUsername"
                        v-model="loginUsername"
                        placeholder="Enter your username"
                      />
                    </div>
                    <div class="form-group text-left">
                      <TextInput
                        rules="required"
                        name="Password"
                        id="loginPassword"
                        v-model="loginPassword"
                        type="password"
                        placeholder="Enter your password"
                      />
                    </div>
                    <button type="submit" class="btn btn-primary">
                      SUBMIT
                    </button>
                  </form>
                </ValidationObserver>
                <div class="col col-12 justify-content-center">
                  <p class="mt-4">
                    <span style="color: red;">*</span> Indicates a required
                    field
                  </p>
                  <p>
                    Forgot your username or password?
                    <a
                      class="text-primary"
                      href="javascript:void(0);"
                      @click="
                        $router.push('/admin/forgot-login').catch(() => {})
                      "
                      >Click here</a
                    >.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import Alert from "@/components/alert/Alert.vue";

export default {
  name: "AdminLogin",
  title: "Admin - Login",
  data() {
    return {
      loginUsername: "",
      loginPassword: "",
      errors: []
    };
  },
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert,
    Alert
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    alert: {
      cache: false,
      get() {
        return this.$store.getters["alert/errorAlert"];
      }
    }
  },
  methods: {
    async azureAdAuth() {
      await this.$store.dispatch("alert/clear");
      const errorMessage = await this.$store.dispatch("auth/azureAdLogin");
      if (errorMessage) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: errorMessage,
          layer: "admin"
        });
      }
    },
    async onSubmit() {
      await this.$store.dispatch("alert/clear");
      const loginUsername = this.loginUsername;
      const loginPassword = this.loginPassword;
      this.$refs.adminLoginForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.adminLoginForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.adminLoginForm.refs[errors[0]["key"]].$el.scrollIntoView(
              {
                behavior: "smooth",
                block: "center"
              }
            );
          }, 100);
        } else {
          this.errors = [];
          const errorMessage = await this.$store.dispatch("auth/login", {
            loginUsername,
            loginPassword,
            authSource: "admin"
          });
          if (errorMessage) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: errorMessage,
              layer: "admin"
            });
          }
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch("auth/clearTokens");
    this.$store.commit("auth/setLoading", false);
  }
};
</script>

<style scoped>
button {
  width: 100%;
}
.right-border {
  border-right-style: solid;
  border-right-color: #e6e6e6;
}
.top-border {
  border-top-style: solid;
  border-top-color: #e6e6e6;
}
</style>
